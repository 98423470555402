
import { userData } from '@/firebase'
import { firestore } from 'firebase'
import { EnrolledFirestoreDoc } from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import CompanyDropdown from './CompanyDropdown.vue'

export default defineComponent({
  components: { CompanyDropdown },
  props: {
    item: {
      type: Object as PropType<EnrolledFirestoreDoc>,
      required: true,
    },
  },
  computed: {
    userRole() {
      return userData?.claims?.role ?? 'unauthorized'
    },
    lastSync(): string {
      if (!this.item.lastSync) return 'No past sync'
      const timestamp = this.item.lastSync as firestore.Timestamp

      return timestamp.toDate().toLocaleString()
    },
  },
})
