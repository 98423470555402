
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { FunctionNames } from '@/enums/FunctionNames'
import { auth, db, FieldValue, functions } from '@/firebase'
import {
  EnrolledFirestoreDoc,
  EnrolmentRequestFirestoreDoc,
} from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import DeleteCompanyModal from './DeleteCompanyModal.vue'

export default defineComponent({
  components: { DeleteCompanyModal },
  props: {
    companyData: {
      type: Object as PropType<EnrolledFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      showDeleteModal: false,
      isDeleting: false,
    }
  },
  computed: {
    isPaused(): boolean {
      return this.companyData.paused ?? false
    },
  },
  methods: {
    async onDeleteConfirm(locationID: string) {
      // Handle delete company in database
      const deleteEnrolledPayload: Pick<
        EnrolledFirestoreDoc,
        'deleted' | 'deletedBy'
      > = {
        deleted: true,
        deletedBy: {
          deletedAt: FieldValue.serverTimestamp(),
          deleterUID: auth.currentUser?.uid ?? 'Unknown',
          deleterName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }
      await db
        .collection(FirestoreColNames.ENROLLED_COMPANIES)
        .doc(locationID)
        .set(deleteEnrolledPayload, {
          merge: true,
        })

      // Handle delete enrolment in database
      const deleteEnrolmentPayload: Pick<
        EnrolmentRequestFirestoreDoc,
        'deleted' | 'deletedBy'
      > = {
        deleted: true,
        deletedBy: {
          deletedAt: FieldValue.serverTimestamp(),
          deleterUID: auth.currentUser?.uid ?? 'Unknown',
          deleterName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }

      const enrolmentQuerySnap = await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .where('locationID', '==', locationID)
        .get()
      for (const doc of enrolmentQuerySnap.docs) {
        await doc.ref.set(deleteEnrolmentPayload, { merge: true })
      }

      this.showDeleteModal = false
    },
    async togglePause() {
      // Handle pause company in database
      const pausePayload: Pick<EnrolledFirestoreDoc, 'paused' | 'pausedBy'> = {
        paused: !this.isPaused,
        pausedBy: {
          pausedAt: FieldValue.serverTimestamp(),
          pauserUID: auth.currentUser?.uid ?? 'Unknown',
          pauserName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }
      await db
        .collection(FirestoreColNames.ENROLLED_COMPANIES)
        .doc(this.companyData.locationID)
        .set(pausePayload, { merge: true })
    },
    async forceIndividualSync() {
      try {
        await functions.httpsCallable(FunctionNames.FORCE_INDIVIDUAL_SYNC)(
          this.companyData.locationID
        )
      } catch (error) {
        console.log(error)
      }
    },
  },
})
