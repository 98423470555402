<template>
  <base-modal :show="show">
    <div class="bg-white px-4 pt-5 pb-8 sm:p-8">
      <div class="mt-3 text-center sm:mt-0 sm:text-left">
        <h3
          class="text-lg leading-6 font-medium text-gray-900"
          id="modal-headline"
        >
          Deleting company {{ companyData.companyNameOnNitro }}
        </h3>
        <p class="text-gray-600 mt-5">
          Please confirm that you want to DELETE this company.
        </p>
      </div>
    </div>
    <div
      class="mt-2 bg-gray-50 px-4 py-3 sm:px-6 sm:pb-6 sm:flex sm:flex-row-reverse"
    >
      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button
          :disabled="loading"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-gray-400"
          @click="$emit('confirm', companyData.locationID)"
        >
          Confirm Delete
        </button>
      </span>
      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
        <button
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          @click="$emit('close')"
        >
          Cancel
        </button>
      </span>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BaseModal from '../reusable/BaseModal.vue'

import { EnrolledFirestoreDoc } from 'types/enrolment'

export default defineComponent({
  components: { BaseModal },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    companyData: {
      type: Object as PropType<EnrolledFirestoreDoc>,
      required: true,
    },
  },
})
</script>

<style scoped></style>
