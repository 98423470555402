<template>
  <div class="static">
    <button
      class="focus:outline-none"
      @click="show = !show"
      @blur="show = false"
    >
      <svg
        class="h-5 w-5 text-gray-600 hover:text-gray-800"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="show"
        class="origin-top-right right-0 mr-8 absolute mt-2 rounded-md shadow-lg z-20 text-left"
      >
        <div
          class="py-1 rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <a
            @click="togglePause"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300 cursor-pointer"
            role="menuitem"
            >{{ isPaused ? 'Unpause' : 'Pause' }}</a
          >
          <a
            class="block px-4 py-2 text-sm text-yellow-500 hover:bg-gray-300 cursor-pointer"
            role="menuitem"
            @click="forceIndividualSync"
            >Force Sync</a
          >
          <a
            class="block px-4 py-2 text-sm text-red-700 hover:bg-gray-300 cursor-pointer"
            role="menuitem"
            @click="showDeleteModal = true"
            >Delete</a
          >
        </div>
      </div>
    </transition>
    <delete-company-modal
      :loading="isDeleting"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
      :companyData="companyData"
      @confirm="onDeleteConfirm"
    />
  </div>
</template>

<script lang="ts">
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { FunctionNames } from '@/enums/FunctionNames'
import { auth, db, FieldValue, functions } from '@/firebase'
import {
  EnrolledFirestoreDoc,
  EnrolmentRequestFirestoreDoc,
} from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import DeleteCompanyModal from './DeleteCompanyModal.vue'

export default defineComponent({
  components: { DeleteCompanyModal },
  props: {
    companyData: {
      type: Object as PropType<EnrolledFirestoreDoc>,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      showDeleteModal: false,
      isDeleting: false,
    }
  },
  computed: {
    isPaused(): boolean {
      return this.companyData.paused ?? false
    },
  },
  methods: {
    async onDeleteConfirm(locationID: string) {
      // Handle delete company in database
      const deleteEnrolledPayload: Pick<
        EnrolledFirestoreDoc,
        'deleted' | 'deletedBy'
      > = {
        deleted: true,
        deletedBy: {
          deletedAt: FieldValue.serverTimestamp(),
          deleterUID: auth.currentUser?.uid ?? 'Unknown',
          deleterName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }
      await db
        .collection(FirestoreColNames.ENROLLED_COMPANIES)
        .doc(locationID)
        .set(deleteEnrolledPayload, {
          merge: true,
        })

      // Handle delete enrolment in database
      const deleteEnrolmentPayload: Pick<
        EnrolmentRequestFirestoreDoc,
        'deleted' | 'deletedBy'
      > = {
        deleted: true,
        deletedBy: {
          deletedAt: FieldValue.serverTimestamp(),
          deleterUID: auth.currentUser?.uid ?? 'Unknown',
          deleterName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }

      const enrolmentQuerySnap = await db
        .collection(FirestoreColNames.ENROLMENT_REQUESTS)
        .where('locationID', '==', locationID)
        .get()
      for (const doc of enrolmentQuerySnap.docs) {
        await doc.ref.set(deleteEnrolmentPayload, { merge: true })
      }

      this.showDeleteModal = false
    },
    async togglePause() {
      // Handle pause company in database
      const pausePayload: Pick<EnrolledFirestoreDoc, 'paused' | 'pausedBy'> = {
        paused: !this.isPaused,
        pausedBy: {
          pausedAt: FieldValue.serverTimestamp(),
          pauserUID: auth.currentUser?.uid ?? 'Unknown',
          pauserName: auth.currentUser?.displayName ?? 'Unknown',
        },
      }
      await db
        .collection(FirestoreColNames.ENROLLED_COMPANIES)
        .doc(this.companyData.locationID)
        .set(pausePayload, { merge: true })
    },
    async forceIndividualSync() {
      try {
        await functions.httpsCallable(FunctionNames.FORCE_INDIVIDUAL_SYNC)(
          this.companyData.locationID
        )
      } catch (error) {
        console.log(error)
      }
    },
  },
})
</script>

<style scoped></style>
