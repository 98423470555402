<template>
  <div>
    <search-bar
      ref="searchBar"
      class="mb-6"
      placeholderText="Search by email, company, name, EFIN, etc ..."
      @change="onSearch"
    />
    <div
      class="shadow-md overflow-hidden border-b border-gray-200 sm:rounded-lg"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-300">
          <tr>
            <th
              class="cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
              @click="changeSort('Company')"
            >
              <span>Company</span>
              <base-sort-arrow
                v-show="currentOrderBy.field === 'companyNameOnNitro'"
                class="ml-1 h-4 w-4 inline-block"
                :direction="currentOrderBy.direction"
              />
            </th>
            <th
              class="cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
              @click="changeSort('EFINs')"
            >
              <span>EFINs</span>
              <base-sort-arrow
                v-show="currentOrderBy.field === 'EFINs'"
                class="ml-1 h-4 w-4 inline-block"
                :direction="currentOrderBy.direction"
              />
            </th>
            <th
              class="cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
              @click="changeSort('Campaigns')"
            >
              <span>Campaigns</span>
              <base-sort-arrow
                v-show="currentOrderBy.field === 'whichCampaigns'"
                class="ml-1 h-4 w-4 inline-block"
                :direction="currentOrderBy.direction"
              />
            </th>
            <th
              class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              <span>Recent sync</span>
            </th>
            <th
              class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              <div
                v-if="role === 'admin'"
                class="cursor-pointer"
                @click="openStatusDropdown = !openStatusDropdown"
                @blur="openStatusDropdown = false"
              >
                <span>Status</span>
                <svg
                  class="w-4 h-4 inline ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <span v-else>Status</span>
              <transition
                v-if="role === 'admin'"
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <status-filter-dropdown v-show="openStatusDropdown" />
              </transition>
            </th>
            <th class="px-6 py-3 bg-gray-50"></th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <div
            v-if="error"
            class="text-red-600 max-w-3xl whitespace-pre-wrap text-center font-semibold my-64"
          >
            <p>{{ error }}</p>
          </div>
          <company-row-skeleton
            v-else-if="loading"
            v-for="n in pageSize"
            :key="n"
          />
          <company-row
            v-else
            v-for="item in data"
            :key="item.submissionID"
            :item="item"
          />
        </tbody>
      </table>
      <pagination-bar
        ref="paginationBar"
        :totalData="totalData"
        :currentPage="currentPage"
        :totalPage="totalPage"
        :pageSize="pageSize"
        @next="nextPage"
        @prev="prevPage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import CompanyRow from './CompanyRow.vue'
import CompanyRowSkeleton from './CompanyRowSkeleton.vue'
import PaginationBar from '@/components/reusable/BasePaginationBottomBar.vue'
import { userData } from '@/firebase'
import useEnrolledCompanies from '@/composables/useEnrolledCompanies'
import SearchBar from '@/components/reusable/BaseSearchBar.vue'
import StatusFilterDropdown from './StatusFilterDropdown.vue'
import BaseSortArrow from '@/components/reusable/BaseSortArrow.vue'

export default defineComponent({
  components: {
    BaseSortArrow,
    CompanyRow,
    CompanyRowSkeleton,
    PaginationBar,
    SearchBar,
    StatusFilterDropdown,
  },
  setup() {
    const paginationBar = ref<null | typeof PaginationBar>(null)
    const searchBar = ref<null | typeof SearchBar>(null)
    const openStatusDropdown = ref(false)
    const role = userData?.claims?.role

    if (!role) return

    const {
      dataOnPage: data,
      loading,
      error,
      currentPage,
      totalPage,
      totalData,
      nextPage,
      prevPage,
      pageSize,
      currentSearchKeyword,
      currentOrderBy,
    } = useEnrolledCompanies()

    const isSearchMode = computed(() => !!currentSearchKeyword?.value ?? false)

    const onSearch = (keyword: string) => {
      if (typeof currentSearchKeyword !== 'undefined') {
        currentSearchKeyword.value = keyword
      }
    }

    const changeSort = (titleToSort: 'Company' | 'EFINs' | 'Campaigns') => {
      if (!currentOrderBy || !currentOrderBy.value) return
      if (isSearchMode.value && currentSearchKeyword && searchBar.value) {
        currentSearchKeyword.value = null
      }
      switch (titleToSort) {
        case 'Company':
          if (currentOrderBy.value.field === 'companyNameOnNitro') {
            currentOrderBy.value.direction =
              currentOrderBy.value.direction === 'desc' ? 'asc' : 'desc'
          } else {
            currentOrderBy.value = {
              field: 'companyNameOnNitro',
              direction: 'asc',
            }
          }
          break
        case 'EFINs':
          if (currentOrderBy.value.field === 'EFINs') {
            currentOrderBy.value.direction =
              currentOrderBy.value.direction === 'desc' ? 'asc' : 'desc'
          } else {
            currentOrderBy.value = {
              field: 'EFINs',
              direction: 'asc',
            }
          }
          break

        case 'Campaigns':
          if (currentOrderBy.value.field === 'whichCampaigns') {
            currentOrderBy.value.direction =
              currentOrderBy.value.direction === 'desc' ? 'asc' : 'desc'
          } else {
            currentOrderBy.value = {
              field: 'whichCampaigns',
              direction: 'asc',
            }
          }
          break

        default:
          break
      }
    }

    return {
      loading,
      data,
      error,
      totalData,
      pageSize,
      currentPage,
      totalPage,
      nextPage,
      prevPage,
      onSearch,
      isSearchMode,
      currentSearchKeyword,
      changeSort,
      currentOrderBy,
      paginationBar,
      openStatusDropdown,
      searchBar,
      role,
    }
  },
})
</script>

<style scoped></style>
