<template>
  <tr>
    <td class="px-6 py-4 whitespace-no-wrap">
      <div class="flex flex-col items-start justify-items-center">
        <div class="text-sm leading-5 font-medium text-gray-900">
          {{ item.companyNameOnNitro }}
        </div>
        <div class="text-sm leading-5 text-gray-500">
          {{ item.emailOnNitro }}
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-no-wrap">
      <div class="text-sm leading-5 text-gray-900">
        <p class="text-xs font-semibold">
          {{ item.EFINs.join(', ') }}
        </p>
      </div>
    </td>
    <td class="px-6 py-4 w-64">
      <p class="text-xs">
        {{ item.whichCampaigns.join(', ') }}
      </p>
    </td>
    <td class="px-6 py-4 text-xs">
      <span>{{ lastSync }}</span>
    </td>
    <td
      class=" px-3 py-4 text-sm text-center leading-3 text-gray-500 flex justify-items-start items-center"
    >
      <span
        v-if="item.deleted"
        class="px-2 mx-1 py-2 text-xs font-semibold rounded-lg bg-red-400 text-red-800"
      >
        Deleted
      </span>
      <span
        v-else-if="item.paused"
        class="px-2 mx-1 py-2 text-xs font-semibold rounded-lg bg-yellow-300 text-yellow-800"
      >
        Not running
      </span>
      <span
        v-else
        class="px-2 mx-1 py-2 text-xs font-semibold rounded-lg bg-green-300 text-green-800"
      >
        Running
      </span>
    </td>
    <td
      class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
    >
      <company-dropdown v-if="userRole === 'admin'" :companyData="item" />
    </td>
  </tr>
</template>

<script lang="ts">
import { userData } from '@/firebase'
import { firestore } from 'firebase'
import { EnrolledFirestoreDoc } from 'types/enrolment'
import { defineComponent, PropType } from 'vue'
import CompanyDropdown from './CompanyDropdown.vue'

export default defineComponent({
  components: { CompanyDropdown },
  props: {
    item: {
      type: Object as PropType<EnrolledFirestoreDoc>,
      required: true,
    },
  },
  computed: {
    userRole() {
      return userData?.claims?.role ?? 'unauthorized'
    },
    lastSync(): string {
      if (!this.item.lastSync) return 'No past sync'
      const timestamp = this.item.lastSync as firestore.Timestamp

      return timestamp.toDate().toLocaleString()
    },
  },
})
</script>

<style scoped></style>
