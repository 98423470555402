<template>
  <div class="max-w-6xl mx-auto py-6 px-3 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <company-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CompanyList from '@/components/companies/CompanyList.vue'

export default defineComponent({
  components: {
    CompanyList,
  },
})
</script>

<style scoped></style>
