<template>
  <tr class="animate-pulse">
    <td class="px-6 py-4 whitespace-no-wrap">
      <div class="flex flex-col items-start justify-items-center">
        <div class="h-5 bg-gray-400 rounded w-full"></div>
        <div class="h-4 bg-gray-400 rounded w-3/4 mt-1"></div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-no-wrap">
      <div class="h-4 bg-gray-400 rounded w-3/4"></div>
      <div class="h-4 bg-gray-400 rounded mt-1 w-3/4"></div>
    </td>
    <td class="px-6 py-4 w-64">
      <div class="h-4 bg-gray-400 rounded w-3/4"></div>
    </td>
    <td
      class=" px-3 py-4 text-sm text-center leading-3 text-gray-500 flex justify-items-start items-center"
    >
      <div class="ml-4 mt-1 h-6 w-16 rounded-lg bg-gray-400"></div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  },
})
</script>

<style scoped></style>
