<template>
  <div
    class="origin-top-left w-40 mt-4 ml-4 absolute rounded shadow-lg bg-white z-20"
  >
    <ul class="list-reset">
      <li v-for="item in options" :key="item.title">
        <p
          class="px-4 py-3 block text-gray-700 hover:bg-gray-300 cursor-pointer"
          @click="toggleOption(item.value)"
        >
          {{ item.title }}
          <input
            class="float-right"
            type="checkbox"
            :checked="currentFilters.includes(item.value)"
          />
        </p>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import useEnrolledCompanies, {
  CompanyStatus,
} from '@/composables/useEnrolledCompanies'
import { defineComponent, reactive, toRefs } from 'vue'

type State = {
  options: Array<{
    title: string
    value: CompanyStatus
  }>
}

export default defineComponent({
  setup() {
    const state = reactive<State>({
      options: [
        {
          title: 'Running',
          value: 'running',
        },
        {
          title: 'Paused',
          value: 'paused',
        },
        {
          title: 'Deleted',
          value: 'deleted',
        },
      ],
    })

    const { currentFilters } = useEnrolledCompanies()
    const toggleOption = (value: CompanyStatus) => {
      if (currentFilters.value.includes(value)) {
        currentFilters.value = currentFilters.value.filter(
          item => item !== value
        )
      } else {
        currentFilters.value.push(value)
      }
    }

    return {
      ...toRefs(state),
      toggleOption,
      currentFilters,
    }
  },
})
</script>

<style scoped>
li {
  border-bottom: 1px solid rgb(226, 226, 226, 0.5);
}
li:last-child {
  border: none;
}
</style>
