
import useEnrolledCompanies, {
  CompanyStatus,
} from '@/composables/useEnrolledCompanies'
import { defineComponent, reactive, toRefs } from 'vue'

type State = {
  options: Array<{
    title: string
    value: CompanyStatus
  }>
}

export default defineComponent({
  setup() {
    const state = reactive<State>({
      options: [
        {
          title: 'Running',
          value: 'running',
        },
        {
          title: 'Paused',
          value: 'paused',
        },
        {
          title: 'Deleted',
          value: 'deleted',
        },
      ],
    })

    const { currentFilters } = useEnrolledCompanies()
    const toggleOption = (value: CompanyStatus) => {
      if (currentFilters.value.includes(value)) {
        currentFilters.value = currentFilters.value.filter(
          item => item !== value
        )
      } else {
        currentFilters.value.push(value)
      }
    }

    return {
      ...toRefs(state),
      toggleOption,
      currentFilters,
    }
  },
})
